import React, { useEffect, useState } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import FormComponent from '../form';
import { parseScreen } from '../../util/form';
import { notification, Card, Button, Modal, Checkbox, Form, Typography, message } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import { requestUpdateProfile } from '../../appRedux/actions/LoginPageInfo';
import { ReactComponent as ManageProfileContactInfo } from '../../assets/icons/ManageProfileContactInfo.svg';

import '../UpdateAccount/styles/custom.less';

const ContactInfo = props => {
	const [checkboxToggle, setCheckboxToggle] = useState(false);
	const [loader, setLoader] = useState(true);
	const [formControl] = Form.useForm();
	const [validate, setValidate] = useState(false);
	const [formState, setFormState] = useState([]);
	const [initialValues, setInitialValues] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [submitLoad, setSubmitLoad] = useState(false);
	const corporateMembershipModuleUrlId = useSelector(
		state => state.loginInfo.corporateMembershipModuleUrlId,
	);
	const dispatch = useDispatch();
	const history = useHistory();
	const { Title } = Typography;

	const [validationsObject, setValidationsObject] = useState({
		error: false,
		validationErrors: {},
	});

	const onCheckBoxChange = () => {
		setCheckboxToggle(!checkboxToggle);
	};

	const consentNotification = () => {
		notification['error']({
			message: 'Please agree to the system terms and conditions.',
		});
	};

	const submitUpdate = () => {
		if (validationsObject.error) {
			let errorFieldIDs = [];
			for (const fieldid in validationsObject.validationErrors) {
				errorFieldIDs.push(fieldid);
			}

			let errorFields = [];
			props.initialData.registerProfileFields.forEach(field => {
				if (errorFieldIDs.includes(field.fieldname)) {
					errorFields.push(field.fieldlabel);
				}
			});

			if (errorFields.length == 1) {
				message.error(`${errorFields} is required`);
			} else {
				message.error(`${errorFields.join(', ')} are required`);
			}

			return false;
		}
		setSubmitLoad(true);
		let formData = new FormData();
		for (let [key, value] of Object.entries(formState)) {
			if (value != undefined) {
				formData.append(key, formState[key]);
			}
		}
		if (checkboxToggle === true) {
			formData.append('contact_consent', 1);
			dispatch(requestUpdateProfile({ formData: formData }));
		} else if (
			checkboxToggle === false &&
			props.auth.consent_object !== undefined &&
			props.auth.consent_object !== ''
		) {
			consentNotification('error');
		} else {
			dispatch(requestUpdateProfile({ formData: formData }));
		}
		if (props.requestManageProfileStatus) {
			props.requestManageProfileStatus(true);
		}
		setSubmitLoad(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const showModal = () => {
		setIsModalVisible(true);
	};
	useEffect(() => {
		let initialFormValues = {};
		for (var key in props.initialData.profileInitialValues) {
			if (
				props.initialData.profileInitialValues[key] == undefined ||
				props.initialData.profileInitialValues[key] == 'undefined'
			) {
				initialFormValues[key] = '';
			} else {
				initialFormValues[key] = props.initialData.profileInitialValues[key];
			}
		}

		setInitialValues(initialFormValues);
	}, [props.initialData.profileInitialValues]);

	useEffect(() => {
		setSubmitLoad(false);
		if (
			props.initialData.userUpdatedMsg === 'User has been updated' &&
			!(props.Redirection ? props.Redirection == 'false' : false)
		) {
			if (corporateMembershipModuleUrlId) {
				history.push(`/main/company/${corporateMembershipModuleUrlId}/home`);
			} else {
				history.push('/main/profilehome');
			}
		}
	}, [props.initialData.userUpdatedMsg]);

	return (
		<Card
			className="card"
			style={{ textAlign: 'left', minWidth: '21em', width: '100%' }}
			bodyStyle={{ marginLeft: '1px' }}
		>
			<ManageProfileContactInfo
				className="manage-profile-page-icons gx-mr-2 gx-mt-1 fg-primary-dark-light"
				style={{ float: 'right' }}
			/>

			<h2 className="gx-text-secondary" style={{ marginBottom: '1rem' }}>
				Manage Profile
			</h2>
			<br />

			{props.auth.consent_object && (
				<div>
					<div style={{ display: 'flex' }}>
						<Checkbox onChange={onCheckBoxChange}></Checkbox>
						<div
							style={{ marginLeft: '2%' }}
							dangerouslySetInnerHTML={{
								__html: props.auth.consent_object?.ConsentRowLabel,
							}}
						></div>
					</div>
					<Link onClick={showModal}>{props.auth.consent_object?.HyperlinkLabel}</Link>
					<Modal
						title={props.auth.consent_object?.DescriptionTitle}
						visible={isModalVisible}
						cancelButtonProps={{ style: { display: 'none' } }}
						okButtonProps={{ style: { display: 'none' } }}
						onCancel={handleCancel}
					>
						<div
							dangerouslySetInnerHTML={{
								__html: props.auth.consent_object?.ConsentDescriptiveText,
							}}
						></div>
					</Modal>
				</div>
			)}
			{initialValues && Object.keys(initialValues).length !== 0 && (
				<div style={{ marginLeft: '1%' }}>
					<FormComponent
						form={formControl}
						initialValues={initialValues}
						formData={parseScreen({
							fieldids: props.initialData.registerProfileFields,
						})}
						validationConfig={{
							module: 'contact',
							// moduleurlid: 'Rsi1dSW', // make it 0 ... 0 doesn't work
							// menuid: '', // don't need anything here
						}}
						onChange={(validations, all) => {
							setValidationsObject(validations);
							setFormState(all);
						}}
						validate={validate}
						primaryColor={props.initialData.login_form?.primary_color}
						secondaryColor={'#808080'}
						textColor={'#404040'}
					/>
					<Button
						onClick={submitUpdate}
						loading={submitLoad}
						className="gx-ml-auto button-primary gx-font-weight-semi-bold shadow-2"
						shape="round"
						style={{
							border: 'none',
							padding: '0 2.5rem',
						}}
						htmlType="submit"
					>
						Update
					</Button>
				</div>
			)}
		</Card>
	);
};

const mapStateToProps = state => {
	const myState = { ...state };

	return {
		auth: myState.auth,
		initialData: myState.loginInfo,
	};
};

export default connect(mapStateToProps)(ContactInfo);
