import { useState, useEffect } from 'react';

//Filter the options based on the filtereValues
const filterOptions = (options, filteredValues) =>
	options &&
	filteredValues &&
	options.filter((option) => filteredValues.find((value) => value == option.id));

export default function useInitialValues(options, filteredValues) {
	const [filteredOptions, setoptions] = useState(
		filterOptions(options, filteredValues) || options,
	);

	useEffect(() => setoptions(filterOptions(options, filteredValues) || options), [
		options,
		filteredValues,
	]);

	return filteredOptions;
}
