import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import LoginSagas from './LoginPageInfo';
import ecommerceSagas from './Ecommerce';
import corporateMembershipSagas from './CorporateMembership';
import commonSagas from './Common';
import userProductsSagas from './UserProducts';
import consumptionSagas from './Consumption';
import FloorPlanSaga from './FloorPlan';
import ecommerceStoreSaga from './EcommerceStore';

export default function* rootSaga(getState) {
	yield all([
		authSagas(),
		LoginSagas(),
		ecommerceSagas(),
		corporateMembershipSagas(),
		commonSagas(),
		userProductsSagas(),
		consumptionSagas(),
		FloorPlanSaga(),
		ecommerceStoreSaga(),
	]);
}
