import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CustomUploadPlugin from '../../../util/UploadAdaptor';
import { Form } from 'antd';
import useRules from '../hooks/useRules';
import Title from '../components/Title';
import Description from '../components/Description';

export const RichTextEditor = ({
	name,
	label,
	value,
	onChange,
	required,
	description,
	primaryColor,
	secondaryColor,
	width,
	height,
	error,
}) => {
	const rules = useRules(required);

	return (
		<div>
			<Title label={label} color={primaryColor} error={error} />
			<Description label={description} color={secondaryColor} />
			<Form.Item
				name={name}
				rules={rules}
				getValueFromEvent={(event, editor) => editor.getData()}
			>
				<CKEditor
					editor={ClassicEditor}
					data={value}
					toolbar={['imageUpload']}
					config={{
						mediaEmbed: { previewsInData: true },
						extraPlugins: [CustomUploadPlugin],
					}}
				/>
			</Form.Item>
		</div>
	);
};
