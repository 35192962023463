import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import auth from '../../../loginAuth/loginAuth';

export const useToken = () => {
	const history = useHistory();
	const appdir = useSelector(state => state.loginInfo.appdir);
	const publicEmbed = useSelector(state => state.settings.publicEmbed);

	const searchParams = new URLSearchParams(history.location.search);

	const authenticate = async () => {
		const uuid = searchParams.get('uuid');
		const ssoToken = searchParams.get('token');

		const existingUuid = auth.getCookie('contact_uuid').replace(/['"]+/g, '');
		const existingSsoToken = auth.getCookie('ssotoken').replace(/['"]+/g, '');
		if (!appdir) return;
		if (!uuid || !ssoToken) return;
		if (uuid === existingUuid || ssoToken === existingSsoToken) return;

		const result = await axios.post(
			`https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm?appdir=${appdir}&module=login&component=login&function=authenticate_user`,
			{
				uuid,
				sso_token: ssoToken,
			},
		);

		// set jwt and login info for login
		auth.setCookie('contact_uuid', JSON.stringify(uuid ?? ''), 7);
		auth.setCookie('ssotoken', JSON.stringify(ssoToken ?? ''), 7);
		auth.setCookie('jwt', JSON.stringify(result.data.jwt ?? ''), 7);
	};

	useEffect(() => {
		if (publicEmbed) authenticate();
	}, [searchParams, appdir]);
};
