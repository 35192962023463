import React, { useState } from 'react';
import { Steps, Card, Popover, Button } from 'antd';
import { useDispatch, useSelector, connect } from 'react-redux';

const ProgressBar = props => {
	//this Step will probably have to be mapped
	const { Step } = Steps;

	// get rid of description
	//*mapp items into cart, if empty display plain msg, your cart is empty */
	// net owining
	// invoice description
	// amount
	// show gross ammount and discount amount
	// take screen 1->0  -> fieldids -> take first item

	let myMenuObj = props.ecommerce.menuObjects;

	let currNum = props.screenNum - 1;

	return (
		<div style={{}}>
			<Card>
				<Steps current={currNum} size="small">
					{myMenuObj
						? Object.keys(myMenuObj).map((key, i) => (
								<Step title={myMenuObj[key].button} />
						  ))
						: ''}
				</Steps>
			</Card>
		</div>
	);
};

const mapStateToProps = state => {
	const myState = { ...state };

	return {
		auth: myState.auth,
		initialData: myState.loginInfo,
		ecommerce: myState.ecommerce,
	};
};

export default connect(mapStateToProps)(ProgressBar);
