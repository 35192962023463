import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
	REQUEST_INITIAL_API,
	REQUEST_PROFILE_INFO,
	REQUEST_APPDIR_COOKIE,
	REQUEST_REGISTER_INITIAL_VALUES,
	REQUEST_EXISTING_ACCOUNTS,
	REQUEST_RETRIEVE_ACCOUNT,
	REQUEST_LOGINFORM_DATA,
	REQUEST_PROFILE_DATA,
	REQUEST_ERASE_PROFILE_DATA,
	REQUEST_CHECK_ACCOUNT,
	FETCH_PROFILE_HOME_LINKS,
	FETCH_NEWS_ITEMS,
	FETCH_EMAIL_MESSAGES_COUNT,
	FETCH_EMAIL_MESSAGES,
	MARK_EMAIL_READ,
	REQUEST_REGISTER_PROFILE_FIELDS,
	REQUEST_PROFILE_INITIAL_VALUES,
	REQUEST_UPDATE_PROFILE,
	REQUEST_CREATE_PROFILE,
	REQUEST_DELETE_PROFILE_MSG,
	REQUEST_UPLOAD_PROFILE_PIC,
	REQUEST_ORDER_HISTORY_DATA,
	UPDATE_EMAIL,
	REQUEST_ORDER_HISTORY_VIEW_DATA,
	REQUEST_ORDER_HISTORY_EMAIL_DATA,
	REQUEST_ORDER_HISTORY_DOWNLOAD_DATA,
	REQUEST_CLEAR_EXISTING_ACCOUNTS,
} from 'constants/ActionTypes';

import {
	receiveInitialAPI,
	receiveProfileInfo,
	receiveAppdirCookie,
	receiveInitialRegisterInfo,
	receiveExistingAccounts,
	receiveRetrieveAccount,
	receiveLoginFormData,
	receiveProfileData,
	receiveEraseProfileData,
	receiveCheckAccount,
	fetchProfileHomeLinksSuccess,
	fetchNewsItemsSuccess,
	fetchEmailMessagesCountSuccess,
	fetchEmailMessagesSuccess,
	markEmailReadSuccess,
	receiveRegisterProfileFields,
	receiveProfileInitialValues,
	receiveUpdateProfile,
	receiveCreateProfile,
	receiveDeleteProfileMsg,
	receiveUploadProfilePic,
	receiveOrderHistoryData,
	updateEmailSuccess,
	receiveOrderHistoryViewData,
	receiveOrderHistoryEmailData,
	receiveOrderHistoryDownloadData,
	updateOrderHistoryRedcucer,
	receiveClearExistingAccounts,
} from '../../appRedux/actions/LoginPageInfo';

import auth from '../../loginAuth/loginAuth';
import LoginAPIs from '../../api/LoginPageInfo';
import api from '../../api';
import { fetchError } from '../actions/Common';

const fetchLoginFormData = async context => {
	let jsonData = await LoginAPIs.getLoginFormData(context.data.appdir);
	auth.setCookie('appdir', JSON.stringify(context.data.appdir), 7);

	return jsonData;
};

const fetchProfileData = async context => {
	let jsonData = await LoginAPIs.getProfileData(
		context.data.source_hex,
		context.data.contact_uuid,
	);
	auth.setCookie('source_hex', JSON.stringify(context.data.source_hex), 7);

	return jsonData;
};

const fetchCheckAccount = async context => {
	let jsonData = await LoginAPIs.getCheckAccount(context.data.appdir, context.data.formData);
	return jsonData;
};

const fetchProfileHomeLinks = async context => {
	return await api.getProfileHomeLinks().then(res => {
		return res.data;
	});
};

const fetchNewsItems = async context => {
	return await api.getNewsItems().then(res => {
		return res.data;
	});
};

const fetchEmailMessagesCount = async context => {
	return await api.getEmailMessagesCount().then(res => {
		return res.data;
	});
};

const fetchEmailMessages = async context => {
	return await api.getEmailMessages().then(res => {
		return res.data;
	});
};

const markEmailRead = async context => {
	return await api
		.markEmailRead({
			emailid: context.payload.emailId,
		})
		.then(res => {
			return res.data;
		});
};

const fetchRegisterProfileFields = async context => {
	let jsonData = await LoginAPIs.getRegisterProfileFields(context.data.appdir);
	return jsonData;
};

const fetchProfileInitialValues = async () => {
	let jsonData = await LoginAPIs.getProfileInitialValues();
	return jsonData;
};

const fetchUpdateProfile = async context => {
	let jsonData = await LoginAPIs.getUpdateProfile(context.data.formData);
	return jsonData;
};

const fetchCreateProfile = async context => {
	let jsonData = await LoginAPIs.getCreateProfile(context.data.formData);
	auth.setCookie('contact_uuid', JSON.stringify(jsonData.uuid), 7);
	auth.setCookie('jwt', JSON.stringify(jsonData.jwt), 7);
	auth.setCookie('ssotoken', JSON.stringify(jsonData.ssotoken), 7);
	auth.setCookie('source_hex', JSON.stringify(context.data.source_hex), 7);

	return jsonData;
};

const fetchUploadProfilePic = async context => {
	let jsonData = await LoginAPIs.getUploadProfilePic(context.data.formData);

	return jsonData;
};

const fetchOrderHistoryData = async context => {
	let jsonData = await LoginAPIs.getOrderHistoryData(context.data.appdir);
	return jsonData;
};

const fetchOrderHistoryViewData = async context => {
	let jsonData = await LoginAPIs.getOrderHistoryViewData(context);
	return jsonData;
};
const fetchOrderHistoryEmailData = async context => {
	let jsonData = await LoginAPIs.getOrderHistoryEmailData(context);
	return jsonData;
};
const fetchOrderHistoryDownloadData = async context => {
	let jsonData = await LoginAPIs.getOrderHistoryDownloadData(context);
	return jsonData;
};

function* getLoginFormData(action) {
	try {
		let loginFormData = yield call(fetchLoginFormData, action);
		auth.setCookie('source_hex', JSON.stringify(loginFormData.source_hex), 7);
		if (!!loginFormData.login_form.title) {
			auth.setCookie('app_dir_title', loginFormData?.login_form.title);
		}
		yield put(receiveLoginFormData(loginFormData));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getProfileData(action) {
	try {
		let result = yield call(fetchProfileData, action);
		yield put(receiveProfileData(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getCheckAccount(action) {
	try {
		let result = yield call(fetchCheckAccount, action);
		yield put(receiveCheckAccount(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getRegisterProfileFields(action) {
	try {
		let result = yield call(fetchRegisterProfileFields, action);
		yield put(receiveRegisterProfileFields(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getProfileInitialValues(action) {
	try {
		let result = yield call(fetchProfileInitialValues, action);
		yield put(receiveProfileInitialValues(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getUpdateProfile(action) {
	try {
		let result = yield call(fetchUpdateProfile, action);
		yield put(receiveUpdateProfile(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getCreateProfile(action) {
	try {
		let result = yield call(fetchCreateProfile, action);
		yield put(receiveCreateProfile(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getUploadProfilePic(action) {
	try {
		let result = yield call(fetchUploadProfilePic, action);
		yield put(receiveUploadProfilePic(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getOrderHistoryData(action) {
	try {
		let result = yield call(fetchOrderHistoryData, action);
		yield put(receiveOrderHistoryData(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getOrderHistoryViewData(action) {
	try {
		let result = yield call(fetchOrderHistoryViewData, action.payload);
		yield put(updateOrderHistoryRedcucer(true, 'showOrderHistoryViewModal'));
		yield put(receiveOrderHistoryViewData(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}
function* getOrderHistoryEmailData(action) {
	try {
		let result = yield call(fetchOrderHistoryEmailData, action.payload);
		yield put(receiveOrderHistoryEmailData(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}
function* getOrderHistoryDownloadData(action) {
	try {
		let result = yield call(fetchOrderHistoryDownloadData, action.payload);
		yield put(receiveOrderHistoryDownloadData(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}
//------

function* getClearExistingAccounts(action) {
	try {
		yield put(receiveClearExistingAccounts(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getDeleteProfileMsg(action) {
	try {
		yield put(receiveDeleteProfileMsg(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getEraseProfileData(action) {
	try {
		yield put(receiveEraseProfileData(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getInitialAPI(action) {
	try {
		yield put(receiveInitialAPI(action));
		auth.setCookie('appdir', JSON.stringify(action.payload.appdir), 7);
		auth.setCookie('source_hex', JSON.stringify(action.payload.source_hex), 7);
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getProfileInfo(action) {
	try {
		yield put(receiveProfileInfo(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getAppdirCookie(action) {
	try {
		yield put(receiveAppdirCookie(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getRegisterInitialValues(action) {
	try {
		yield put(receiveInitialRegisterInfo(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getExistingAccounts(action) {
	try {
		yield put(receiveExistingAccounts(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getRetrieveAccount(action) {
	try {
		yield put(receiveRetrieveAccount(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* fetchProfileHomeLinksSaga(context) {
	try {
		let response = yield call(fetchProfileHomeLinks, context);
		yield put(fetchProfileHomeLinksSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* fetchNewsItemsSaga(context) {
	try {
		let response = yield call(fetchNewsItems, context);
		yield put(fetchNewsItemsSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* fetchEmailMessagesCountSaga(context) {
	try {
		let response = yield call(fetchEmailMessagesCount, context);
		yield put(fetchEmailMessagesCountSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* fetchEmailMessagesSaga(context) {
	try {
		let response = yield call(fetchEmailMessages, context);
		yield put(fetchEmailMessagesSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* markEmailReadSaga(context) {
	try {
		let response = yield call(markEmailRead, context);
		yield put(markEmailReadSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* updateEmailSaga(context) {
	try {
		yield put(updateEmailSuccess(context.payload.email));
	} catch (error) {
		yield put(fetchError(error));
	}
}

export function* clearExistingAccounts() {
	yield takeEvery(REQUEST_CLEAR_EXISTING_ACCOUNTS, getClearExistingAccounts);
}

export function* loginFormData() {
	yield takeEvery(REQUEST_LOGINFORM_DATA, getLoginFormData);
}

export function* profileData() {
	yield takeEvery(REQUEST_PROFILE_DATA, getProfileData);
}

export function* checkAccount() {
	yield takeEvery(REQUEST_CHECK_ACCOUNT, getCheckAccount);
}

export function* registerProfileFields() {
	yield takeEvery(REQUEST_REGISTER_PROFILE_FIELDS, getRegisterProfileFields);
}

export function* profileInitialValues() {
	yield takeEvery(REQUEST_PROFILE_INITIAL_VALUES, getProfileInitialValues);
}

export function* updateProfile() {
	yield takeEvery(REQUEST_UPDATE_PROFILE, getUpdateProfile);
}

export function* createProfile() {
	yield takeEvery(REQUEST_CREATE_PROFILE, getCreateProfile);
}

export function* deleteProfileMsg() {
	yield takeEvery(REQUEST_DELETE_PROFILE_MSG, getDeleteProfileMsg);
}

export function* uploadProfilePic() {
	yield takeEvery(REQUEST_UPLOAD_PROFILE_PIC, getUploadProfilePic);
}

export function* orderHistoryData() {
	yield takeLatest(REQUEST_ORDER_HISTORY_DATA, getOrderHistoryData);
}
export function* orderHistoryViewData() {
	yield takeLatest(REQUEST_ORDER_HISTORY_VIEW_DATA, getOrderHistoryViewData);
}
export function* orderHistoryEmailData() {
	yield takeLatest(REQUEST_ORDER_HISTORY_EMAIL_DATA, getOrderHistoryEmailData);
}
export function* orderHistoryDownloadData() {
	yield takeLatest(REQUEST_ORDER_HISTORY_DOWNLOAD_DATA, getOrderHistoryDownloadData);
}

//----------

export function* eraseProfileData() {
	yield takeEvery(REQUEST_ERASE_PROFILE_DATA, getEraseProfileData);
}

export function* initialAPI() {
	yield takeEvery(REQUEST_INITIAL_API, getInitialAPI);
}

export function* profileInfo() {
	yield takeEvery(REQUEST_PROFILE_INFO, getProfileInfo);
}

export function* appdirCookie() {
	yield takeEvery(REQUEST_APPDIR_COOKIE, getAppdirCookie);
}

export function* initialRegisterInfo() {
	yield takeEvery(REQUEST_REGISTER_INITIAL_VALUES, getRegisterInitialValues);
}

export function* existingAccounts() {
	yield takeEvery(REQUEST_EXISTING_ACCOUNTS, getExistingAccounts);
}

export function* retrieveAccount() {
	yield takeEvery(REQUEST_RETRIEVE_ACCOUNT, getRetrieveAccount);
}

export function* getProfileHomeLinksActionWatcher() {
	yield takeLatest(FETCH_PROFILE_HOME_LINKS, fetchProfileHomeLinksSaga);
}

export function* getNewsItemsActionWatcher() {
	yield takeLatest(FETCH_NEWS_ITEMS, fetchNewsItemsSaga);
}

export function* getEmailMessagesCountActionWatcher() {
	yield takeLatest(FETCH_EMAIL_MESSAGES_COUNT, fetchEmailMessagesCountSaga);
}

export function* getEmailMessagesActionWatcher() {
	yield takeLatest(FETCH_EMAIL_MESSAGES, fetchEmailMessagesSaga);
}

export function* markEmailReadActionWatcher() {
	yield takeLatest(MARK_EMAIL_READ, markEmailReadSaga);
}

export function* UpdateProfile() {
	yield takeEvery(REQUEST_UPDATE_PROFILE, getUpdateProfile);
}

export function* updateEmailActionWatcher() {
	yield takeLatest(UPDATE_EMAIL, updateEmailSaga);
}

export default function* rootSaga() {
	yield all([
		fork(checkAccount),
		fork(getEraseProfileData),
		fork(profileData),
		fork(loginFormData),
		fork(initialAPI),
		fork(profileInfo),
		fork(appdirCookie),
		fork(initialRegisterInfo),
		fork(getExistingAccounts),
		fork(retrieveAccount),
		fork(getProfileHomeLinksActionWatcher),
		fork(getNewsItemsActionWatcher),
		fork(getEmailMessagesCountActionWatcher),
		fork(getEmailMessagesActionWatcher),
		fork(markEmailReadActionWatcher),
		fork(registerProfileFields),
		fork(profileInitialValues),
		fork(updateProfile),
		fork(createProfile),
		fork(deleteProfileMsg),
		fork(uploadProfilePic),
		fork(orderHistoryData),
		fork(updateEmailActionWatcher),
		fork(orderHistoryViewData),
		fork(orderHistoryEmailData),
		fork(orderHistoryDownloadData),
		fork(clearExistingAccounts),
	]);
}
