import formItem from '../../components/form/formItem';
import {
	FORM_DATA_FETCHING,
	FORM_DATA_FETCH_SUCCESS,
	FORM_DATA_FETCH_ERROR,
	SAVE_FORM_SCREEN_DATA,
} from '../../constants/ActionTypes';

export const formDataFetching = (formInfo, flag) => ({
	type: FORM_DATA_FETCHING,
	formInfo,
	flag,
});

export const formDataFetchSuccess = (payload) => ({
	type: FORM_DATA_FETCH_SUCCESS,
	payload,
});

export const formDataFetchError = (formInfo) => ({
	type: FORM_DATA_FETCH_ERROR,
	formInfo,
});

export const saveFormScreenData = (formInfo) => ({
	type: SAVE_FORM_SCREEN_DATA,
	formInfo,
});
