import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { requestToFetchNewsItems } from '../../../appRedux/actions';
import '../../Ecommerce/Store/SelectionPage/selection.css';

const ProfileSocialFeed = props => {
	useEffect(() => {
		if (!props.loginInfo.newsItemsFetched) {
			props.requestToFetchNewsItems();
		}
	}, []);

	return (
		<div className="gx-app-login-container">
			{props.loginInfo.newsItemsFetched && (
				<Card
					title={'Latest News'}
					headStyle={{
						backgroundColor: props.loginInfo.login_form.primary_color,
						color: 'white',
						border: 0,
						fontSize: '20px',
					}}
					className="gx-mb-0 gx-h-100 product-card card"
					size="large"
				>
					{props.loginInfo.newsItems.map(newsItem => {
						return (
							<Card title={newsItem['date']} headStyle={{ textAlign: 'center' }}>
								{<div style={{ paddingTop: '5%' }}>{newsItem['content']}</div>}
							</Card>
						);
					})}
				</Card>
			)}
		</div>
	);
};

function matchDispatchToProps(dispatch) {
	return bindActionCreators(
		Object.assign({
			requestToFetchNewsItems: requestToFetchNewsItems,
		}),
		dispatch,
	);
}

function mapStateToProps(state) {
	return {
		loginInfo: state.loginInfo,
	};
}

export default connect(mapStateToProps, matchDispatchToProps)(ProfileSocialFeed);
