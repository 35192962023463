import React from 'react';
import { Form, Radio, Image, Card } from 'antd';
import Title from '../components/Title';
import Description from '../components/Description';
import useRules from '../hooks/useRules';
import NormalText from '../components/NormalText';
import RadioLabel from '../components/RadioLabel';
import useFilteredOptions from '../hooks/useFilteredOptions';

interface Props {
	name: string;
	label: string;
	options: Array<{
		id: any;
		label: string;
		value: any;
		disabled: boolean;
		text: string;
	}>;
	value: any;
	onChange: any;
	required: string;
	description: string;
	primaryColor: string;
	secondaryColor: string;
	textColor: string;
	error: string;
	filteredValues?: any;
	imgUrl?: any;
}

export const RadioCard = ({
	label,
	name,
	options,
	value,
	onChange,
	required,
	description,
	primaryColor,
	secondaryColor,
	textColor,
	filteredValues,
	error,
	imgUrl,
}: Props) => {
	const rules = useRules(required);
	const filteredOptions: any = useFilteredOptions(options, filteredValues);

	return (
		<div>
			<Title
				label={label}
				color={primaryColor}
				error={error}
				required={required}
				name={name}
			/>
			<Description label={description} color={secondaryColor} />
			<Form.Item name={name} rules={rules}>
				<Radio.Group onChange={onChange} value={value}>
					{filteredOptions.map((option: any) => (
						<div key={option.id} style={{ margin: '7px 0px', color: 'red' }}>
							<Card>
								<Radio key={option.id} value={option.id}>
									<NormalText label={`${option.label} - `} color={textColor} />
									<NormalText
										label={`$${option.value.toLocaleString()}`}
										color={primaryColor}
										className={'gx-text-primary'}
									/>
									<Image width={200} src={imgUrl} />
								</Radio>
								<RadioLabel label={option.text} color={secondaryColor} />
							</Card>
						</div>
					))}
				</Radio.Group>
			</Form.Item>
		</div>
	);
};
