import { useDispatch, connect } from 'react-redux';
import { Button, Card } from 'antd';
import { Redirect, useHistory } from 'react-router-dom';

import React from 'react';
import {
	requestRetrieveAccount,
	requestClearExistingAccounts,
} from '../../../appRedux/actions/LoginPageInfo';

const ExistingAccounts = props => {
	const existing_accounts = props.initialData.existingAccounts;

	const history = useHistory();
	const dispatch = useDispatch();

	if (existing_accounts === '') {
		return (
			<Redirect
				to={{
					pathname: '/signin',
				}}
			/>
		);
	}

	let login_form = props.initialData.login_form;

	const goBack = () => {
		dispatch(requestClearExistingAccounts(props));
		history.push('/findaccount');
	};

	return (
		<div className="gx-app-login-container">
			<Button onClick={goBack}>Back</Button>

			<Card className="gx-card" style={{ textAlign: 'center' }}>
				{
					<div style={{ textAlign: 'center' }}>
						<img src={login_form.logo} />
					</div>
				}
				<h2>Existing Accounts</h2>
				<hr />
				{existing_accounts
					? existing_accounts.map(item => (
							<ButtonItem
								fullname={item.fullname}
								email={item.email}
								company={item.company}
								phonearray={item.phonearray}
								UUID={item.UUID}
								primary_color={login_form.primary_color}
							/>
					  ))
					: ''}
			</Card>
		</div>
	);
};

const ButtonItem = myProps => {
	const history = useHistory();
	const dispatch = useDispatch();
	const selectAccount = values => {
		dispatch(requestRetrieveAccount(values));
		history.push('/retrieveaccount');
	};

	return (
		<div>
			<h4>{myProps.fullname}</h4>
			<h4>{myProps.email}</h4>
			<h4>{myProps.company}</h4>
			<Button
				type="default"
				key="submit"
				htmlType="submit"
				onClick={() => selectAccount(myProps)}
				style={{ backgroundColor: myProps.primary_color, color: '#fff', width: '50%' }}
			>
				This is Me
			</Button>
			<br />
			<br />
			<br />
		</div>
	);
};

const mapStateToProps = state => {
	return {
		auth: state.auth,
		initialData: state.loginInfo,
	};
};

export default connect(mapStateToProps)(ExistingAccounts);
