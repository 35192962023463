import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Settings';
import Auth from './Auth';
import LoginPageInfo from './LoginPageInfo';
import Ecommerce from './Ecommerce';
import CorporateMembership from './CorporateMembership';
import form from './form';
import formValidation from './formValidation';
import Common from './Common';
import UserProducts from './UserProducts';
import Consumption from './Consumption';
import FloorPlan from './FloorPlan';

const createRootReducer = history =>
	combineReducers({
		router: connectRouter(history),
		settings: Settings,
		auth: Auth,
		loginInfo: LoginPageInfo,
		ecommerce: Ecommerce,
		form,
		formValidation,
		corporateMembership: CorporateMembership,
		common: Common,
		userProducts: UserProducts,
		consumption: Consumption,
		exhibFloor: FloorPlan
	});

export default createRootReducer;
