import React from 'react';
import { useBundleInfo } from '../hooks/useBundleInfo';

import './bundle.css';

// for rendering a product when it is a bundle and of type print.
const BundleTextRender = ({ product }) => {
	const [, children, , , , ,] = useBundleInfo(product.id);

	if (!product.childrenIds.length)
		return (
			<article className="gx-mt-4 gx-mb-3">
				<h2 className="gx-mb-2">{product.title}</h2>
				<p
					className="gx-mb-2 line-height-1"
					dangerouslySetInnerHTML={{ __html: product.description }}
				/>
			</article>
		);

	return (
		<article className="gx-my-4">
			<h2 className="gx-mb-2">{product.title}</h2>
			<p
				className="gx-mb-1 line-height-1"
				dangerouslySetInnerHTML={{ __html: product.description }}
			/>

			<div className="gx-mr-4 gx-ml-3">
				{children?.map((child, index) => (
					<div key={child.id} className="gx-d-flex">
						<div className="gx-mr-2">
							<div className="top-guide"></div>
							{index < children.length - 1 && <div className="bottom-guide"></div>}
						</div>
						<BundleTextRender product={child} />
					</div>
				))}
			</div>
		</article>
	);
};

export default BundleTextRender;
