import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import CircularProgress from '../CircularProgress';
import Widget from '../Widget';
import { Card, Row, Col, Menu, Dropdown } from 'antd';
import OrderHistory from './OrderHistory';
import ContactInfo from './ContactInfo';
import UpdatePicture from './UpdatePicture';
import ResetPassword from '../FindAccount/ResetPassword';

import auth from '../../loginAuth/loginAuth';
import {
	requestRegisterProfileFields,
	requestProfileInitialValues,
	requestProfileData,
} from '../../appRedux/actions/LoginPageInfo';

import '../UpdateAccount/styles/custom.less';

import { ReactComponent as ManageProfileContactInfo } from '../../assets/icons/ManageProfileContactInfo.svg';
import { ReactComponent as ManageProfileMain } from '../../assets/icons/ManageProfileMain.svg';
import { ReactComponent as ManageProfileOrderHistory } from '../../assets/icons/ManageProfileOrderHistory.svg';
import { ReactComponent as ManageProfileResetPassword } from '../../assets/icons/ManageProfileResetPassword.svg';
import { ReactComponent as ManageProfileUploadPicture } from '../../assets/icons/ManageProfileUploadPicture.svg';

import { DownOutlined } from '@ant-design/icons';

const UpdateAccount = props => {
	const [loader, setLoader] = useState(true);
	const [currentTab, setCurrentTab] = useState('ContactInfo');
	const [currentName, setCurrentName] = useState('Contact Info');

	const [activeKey, setActiveKey] = useState('1');

	const dispatch = useDispatch();

	const width = props.settings.width;
	const mobileThreshold = 500;

	useEffect(() => {
		let appdir = props.initialData.appdir
			? props.initialData.appdir
			: JSON.parse(auth.getCookie('appdir'));

		if (!props.initialData.registerProfileFieldsFetched) {
			dispatch(requestRegisterProfileFields({ appdir: appdir }));
		}

		if (!props.initialData.profileInitialValuesFetched) {
			dispatch(requestProfileInitialValues());
		}

		if (!props.initialData.reviewerID) {
			dispatch(
				requestProfileData({
					source_hex: JSON.parse(auth.getCookie('source_hex')),
					contact_uuid: JSON.parse(auth.getCookie('contact_uuid')),
				}),
			);
		}
	}, []);

	useEffect(() => {
		if (props.initialData.registerProfileFields && props.initialData.profileInitialValues) {
			setLoader(false);
		}
	}, [props.initialData.registerProfileFields, props.initialData.profileInitialValues]);

	const buttonClicked = ({ key, name }) => {
		let noSpaceText = name.replace(/\s/g, '');
		setCurrentTab(noSpaceText);
		setActiveKey(key);
		setCurrentName(name);
	};

	const renderComponent = () => {
		switch (currentTab) {
			case 'ContactInfo':
				return <ContactInfo />;
			case 'ResetPassword':
				return <ResetPassword />;
			case 'UpdatePicture':
				return <UpdatePicture />;
			case 'OrderHistory':
				return <OrderHistory />;
		}
	};

	const leftMenuListItems = [
		{
			key: '1',
			name: 'Contact Info',
			icon: (
				<ManageProfileContactInfo className="manage-profile-icons gx-mb-1 fg-primary-dark-light" />
			),
		},
		{
			key: '2',
			name: 'Reset Password',
			icon: (
				<ManageProfileResetPassword className="manage-profile-icons gx-mb-1 fg-primary-dark-light" />
			),
		},
		{
			key: '3',
			name: 'Update Picture',
			icon: (
				<ManageProfileUploadPicture className="manage-profile-icons gx-mb-1 fg-primary-dark-light" />
			),
		},
		// {
		// 	key: '4',
		// 	name: 'Directories',
		// },
		// {
		// 	key: '5',
		// 	name: 'Membership',
		// },
		// {
		// 	key: '6',
		// 	name: 'Newsletter Preferences',
		// },
		{
			key: '7',
			name: 'Order History',
			icon: (
				<ManageProfileOrderHistory className="manage-profile-icons gx-mb-1 fg-primary-dark-light" />
			),
		},
	];

	const menuComponent = (
		<Menu
			mode={width < mobileThreshold ? 'horizontal' : 'vertical'}
			style={{ borderRight: 'none', marginLeft: '0px' }}
			defaultSelectedKeys={[]}
		>
			{leftMenuListItems.map(menu => {
				return (
					<Menu.Item
						key={menu.key}
						onClick={() => buttonClicked(menu)}
						style={{
							listStyle: 'none',
							padding: '0',
						}}
					>
						<div className="gx-d-flex gx-align-items-center">
							<div className="gx-ml-2">{menu.icon}</div>
							<span
								style={{
									marginLeft: '5%',
									fontSize: '16px',
								}}
							>
								{menu.button}
							</span>
							<p
								className={`gx-list-menu-vertical gx-ml-2 gx-mb-3 ${
									activeKey === menu.key
										? 'gx-list-menu-vertical-active gx-text-secondary'
										: 'gx-text-secondary'
								}`}
								style={{
									textAlign: 'left',
									cursor: 'pointer',
								}}
								onClick={() => buttonClicked(menu)}
							>
								{menu.name}
							</p>
						</div>
					</Menu.Item>
				);
			})}
		</Menu>
	);

	return (
		<div className="container">
			{loader === true ? (
				<Widget styleName="gx-card">
					<CircularProgress className="gx-profile-content" />
				</Widget>
			) : (
				<Row>
					<Col xl={6} lg={24} md={24} sm={24} xs={24}>
						<div>
							<Card className="card">
								<div className="gx-d-flex gx-align-items-center">
									<h2
										className="gx-text-secondary"
										style={{ marginRight: '1rem' }}
									>
										Manage Profile
									</h2>
									<ManageProfileMain className="manage-profile-icons gx-mr-2 gx-mt-1 fg-primary-dark-light" />
								</div>

								<Col xl={0} lg={24} md={24} sm={24} xs={24}>
									<Dropdown overlay={menuComponent} trigger={['click']}>
										<h2
											className="ant-dropdown-link fg-primary"
											style={{ width: '20rem' }}
											onClick={e => e.preventDefault()}
										>
											{currentName} <DownOutlined />
										</h2>
									</Dropdown>
								</Col>
								<Col xl={24} lg={0} md={0} sm={0} xs={0}>
									{menuComponent}
								</Col>
							</Card>
						</div>
					</Col>
					<Col xl={18} lg={24} md={24} sm={24} xs={24}>
						{renderComponent()}
					</Col>
				</Row>
			)}
		</div>
	);
};

const mapStateToProps = state => {
	const myState = { ...state };

	return {
		auth: myState.auth,
		initialData: myState.loginInfo,
		settings: myState.settings,
	};
};

export default connect(mapStateToProps)(UpdateAccount);
