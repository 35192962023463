import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Card } from 'antd';
import { useHistory, Redirect } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import {
	requestAppdirCookie,
	requestInitialRegisterInfo,
	requestCheckAccount,
} from '../../../appRedux/actions/LoginPageInfo';

import auth from '../../../loginAuth/loginAuth';
import IntlMessages from 'util/IntlMessages';

const FormItem = Form.Item;

const InitialSignUp = props => {
	const [submitLoad, setSubmitLoad] = useState(false);
	const [formValues, setFormValues] = useState('');

	const dispatch = useDispatch();
	const history = useHistory();

	const onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
	};

	useEffect(() => {
		if (props.initialData.emailExists == 1) {
			history.push('/retrieveaccount');
		} else if (props.initialData.existingAccounts?.length > 0) {
			history.push('/existingaccounts');
		} else if (props.initialData.emailExists === 0) {
			dispatch(requestInitialRegisterInfo(formValues));
			history.push('/register');
		}
	}, [props.initialData.existingAccounts, props.initialData.emailExists]);

	let login_form = props.login_form;
	if (login_form === '') {
		return (
			<Redirect
				to={{
					pathname: '/signin',
				}}
			/>
		);
	}

	const checkAccount = values => {
		setSubmitLoad(true);
		let formData = new FormData();
		formData.append('email', values.email);
		formData.append('lastname', values.lastname);

		let appdir = props.initialData.appdir;

		dispatch(
			requestCheckAccount({
				appdir: appdir,
				formData: formData,
			}),
		);
		setFormValues(values);
	};

	return (
		<div className="gx-app-login-container">
			<Card className="gx-card">
				{
					<div style={{ textAlign: 'center' }}>
						<img src={login_form.logo} />
					</div>
				}
				<h2>{props.title_label}</h2>
				<Form
					initialValues={{ remember: true }}
					name="myForm"
					onFinish={checkAccount}
					onFinishFailed={onFinishFailed}
					className="gx-signin-form gx-form-row0"
				>
					<Form.Item
						// initialValue={loginInfo?JSON.parse(loginInfo).email:''}
						labelCol={{ span: 24 }}
						label={login_form.firstname_label}
						rules={[
							{
								required: true,
								message: 'Please input your first name!',
							},
						]}
						name="firstname"
					>
						<Input placeholder={login_form.firstname_label} />
					</Form.Item>

					<Form.Item
						// initialValue={loginInfo?JSON.parse(loginInfo).email:''}
						label={login_form.lastname_label}
						labelCol={{ span: 24 }}
						rules={[
							{
								required: true,
								message: 'Please input your last name!',
							},
						]}
						name="lastname"
					>
						<Input placeholder={login_form.lastname_label} />
					</Form.Item>

					<Form.Item
						// initialValue={loginInfo?JSON.parse(loginInfo).email:''}
						label="Email"
						labelCol={{ span: 24 }}
						validateTrigger="onSubmit"
						rules={[
							{
								type: login_form.email_input_type,
								message: login_form.invalid_email_label,
							},
							{
								required: true,
								message: login_form.no_email_label,
							},
						]}
						name="email"
					>
						<Input placeholder="Email" />
					</Form.Item>
					<FormItem>
						<Button
							form="myForm"
							type="default"
							className="login-form-button gx-mt-1"
							key="submit"
							htmlType="submit"
							loading={submitLoad}
							style={{
								backgroundColor: login_form.primary_color
									? login_form.primary_color
									: '#33b4fd',
								color: '#fff',
								width: '100%',
							}}
						>
							<IntlMessages id={props.title_label} />
						</Button>
					</FormItem>
				</Form>
			</Card>
		</div>
	);
};

const mapStateToProps = state => {
	const myState = { ...state };

	return {
		auth: myState.auth,
		initialData: myState.loginInfo,
	};
};

export default connect(mapStateToProps)(InitialSignUp);
