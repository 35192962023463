import {
	FETCH_ERROR,
	FETCH_START,
	FETCH_SUCCESS,
	HIDE_MESSAGE,
	SHOW_MESSAGE,
	REQUEST_SEND_PAYMENT,
	RECEIVE_SEND_PAYMENT,
	REQUEST_SEND_PAYMENT_EXHIBITOR,
	RECEIVE_SEND_PAYMENT_EXHIBITOR,
	REQUEST_RESET_INVALID_CREDIT_CARD,
	RECEIVE_RESET_INVALID_CREDIT_CARD,
	REQUEST_ERASE_COMMON_DATA,
	RECEIVE_ERASE_COMMON_DATA,
	REQUEST_CLEAR_CREDIT_CARD_NOT_ACCEPTED_MSG,
	RECEIVE_CLEAR_CREDIT_CARD_NOT_ACCEPTED_MSG,
	REQUEST_RESET_PAYMENT_SUCCESS,
	RECEIVE_RESET_PAYMENT_SUCCESS,
	REQUEST_PAYMENT_LOADER,
} from '../../constants/ActionTypes';

export const fetchStart = () => {
	return {
		type: FETCH_START,
	};
};

export const fetchSuccess = () => {
	return {
		type: FETCH_SUCCESS,
	};
};

export const fetchError = error => {
	return {
		type: FETCH_ERROR,
		payload: error,
	};
};

export const showMessage = message => {
	return {
		type: SHOW_MESSAGE,
		payload: message,
	};
};

export const hideMessage = () => {
	return {
		type: HIDE_MESSAGE,
	};
};

export const requestSendPayment = data => {
	return {
		type: REQUEST_SEND_PAYMENT,
		data,
	};
};

export const receiveSendPayment = data => {
	return {
		type: RECEIVE_SEND_PAYMENT,
		payload: data,
	};
};

export const requestSendPaymentExhibitor = data => {
	return {
		type: REQUEST_SEND_PAYMENT_EXHIBITOR,
		data,
	};
};

export const requestPaymentLoader = data => {
	return {
		type: REQUEST_PAYMENT_LOADER,
		payload: data,
	};
};

export const receiveSendPaymentExhibitor = data => {
	return {
		type: RECEIVE_SEND_PAYMENT_EXHIBITOR,
		payload: data,
	};
};

export const requestResetInvalidCreditCard = error => {
	return {
		type: REQUEST_RESET_INVALID_CREDIT_CARD,
		payload: error,
	};
};

export const receiveResetInvalidCreditCard = message => {
	return {
		type: RECEIVE_RESET_INVALID_CREDIT_CARD,
		payload: message,
	};
};

export const requestEraseCommonData = data => {
	return {
		type: REQUEST_ERASE_COMMON_DATA,
		payload: data,
	};
};

export const receiveEraseCommonData = data => {
	return {
		type: RECEIVE_ERASE_COMMON_DATA,
		payload: data,
	};
};

export const requestClearCreditCardNotAcceptedMsg = data => {
	return {
		type: REQUEST_CLEAR_CREDIT_CARD_NOT_ACCEPTED_MSG,
		payload: data,
	};
};

export const receiveClearCreditCardNotAcceptedMsg = data => {
	return {
		type: RECEIVE_CLEAR_CREDIT_CARD_NOT_ACCEPTED_MSG,
		payload: data,
	};
};

export const requestResetPaymentSuccess = data => {
	return {
		type: REQUEST_RESET_PAYMENT_SUCCESS,
		payload: data,
	};
};

export const receiveResetPaymentSuccess = data => {
	return {
		type: RECEIVE_RESET_PAYMENT_SUCCESS,
		payload: data,
	};
};
