import * as actionTypes from 'constants/ActionTypes'

let INITIAL_STATE = {
   loading: false,
   companyInfo: [],
   config: {},
   selectedItems:{},
   boothConfiguration:{},
   boothConfigurationLoading: false,
   exhibitors:{},
   exhibitorsInfo:{},
   boothsRequestLoader: false
}

function FloorPlan(state = INITIAL_STATE, action) {
   const { payload, type } = action;
   switch (type) {
      
      case actionTypes.FETCH_COMPANY_INFO_REQUEST: {
         return { ...state, loading: true }
      }
      case actionTypes.FETCH_COMPANY_INFO_SUCCESS: {
         return { ...state, loading: false, companyInfo: payload }
      }
      case actionTypes.FETCH_COMPANY_INFO_FAILURE: {
         return { ...state, loading: false }
      }

      case actionTypes.FETCH_BOOTH_CONFIGURATION_REQUEST: {
         return { ...state, boothConfigurationLoading: true }
      }
      case actionTypes.FETCH_BOOTH_CONFIGURATION_SUCCESS: {
         return { ...state, boothConfigurationLoading: false, boothConfiguration: payload }
      }
      case actionTypes.FETCH_BOOTH_CONFIGURATION_FAILURE: {
         return { ...state, boothConfigurationLoading: false }
      }
      case actionTypes.UPDATE_EXHIBITOR_HASH_MAP: {
         return { ...state, exhibitors: payload.exhibitors, exhibitorsInfo: payload.exhibitorsInfo }
      }
      default:
         return state
   }
}

export default FloorPlan