import React from 'react';
import Description from '../components/Description';
import Title from '../components/Title';

interface Props {
	label: string;
	description: string;
	primaryColor: string;
	secondaryColor: string;
	required: string;
	name: string;
}

export const LineBreak = ({
	label,
	description,
	primaryColor,
	secondaryColor,
	required,
	name,
}: Props) => {
	return (
		<div>
			<Title label={label} color={primaryColor} required={required} name={name} />
			<Description label={description} color={secondaryColor} />
		</div>
	);
};
