import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

const Dashboard = ({ match }) => (
	<div className="container">
		<Switch>
			<Redirect exact from={`${match.url}/`} to={`${match.url}`} />
		</Switch>
	</div>
);

export default Dashboard;
