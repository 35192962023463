import React, { useEffect, useState } from 'react';
import { Checkbox, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const ConsentForm = (props) => {
	const [modalVisible, setModalVisible] = useState(false);

	const showModal = (value) => {
		setModalVisible(value);
	};

	return (
		<div>
			<div style={{ display: 'flex' }}>
				<Checkbox onChange={props.onCheckBoxChange}></Checkbox>
				<div
					style={{ 'margin-left': '2%' }}
					dangerouslySetInnerHTML={{
						__html: props.loginInfo.consent_object.ConsentRowLabel,
					}}
				></div>
			</div>
			<Link onClick={showModal}>{props.loginInfo.consent_object.HyperlinkLabel}</Link>
			<Modal
				title={props.loginInfo.consent_object.DescriptionTitle}
				visible={modalVisible}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				onCancel={() => setModalVisible(false)}
			>
				<div
					dangerouslySetInnerHTML={{
						__html: props.loginInfo.consent_object.ConsentDescriptiveText,
					}}
				></div>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loginInfo: state.loginInfo,
	};
};

export default connect(mapStateToProps)(ConsentForm);
