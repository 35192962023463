import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, Table } from 'antd';
import { requestToFetchEmailMessages, requestToMarkEmailRead } from '../../../appRedux/actions';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import CircularProgress from '../../CircularProgress';
import Widget from '../../Widget';
import OpenModal from '../../Common/OpenModal';
import { ReactComponent as Date } from '../../../assets/icons/date.svg';
import { ReactComponent as Message } from '../../../assets/icons/Message.svg';
import '../ProfileHome/styles/custom.less';

const MessagesList = props => {
	const [loader, setLoader] = useState(true);
	const messageOpened = message => {
		if (!message.date_opened) {
			props.requestToMarkEmailRead({
				emailId: message.id,
			});
		}
	};

	const emailTablecolumns = [
		{
			title: (
				<div className="gx-d-flex gx-align-items-center">
					<Date className="date-icon gx-mr-2 gx-mt-1" />
					<p
						className="gx-text-secondary"
						style={{
							textAlign: 'left',
						}}
					>
						Sent
					</p>
				</div>
			),
			dataIndex: 'date_sent',
			key: 'date_sent',
		},
		{
			title: (
				<div className="gx-d-flex gx-align-items-center">
					<p
						className="gx-text-secondary"
						style={{
							textAlign: 'left',
						}}
					>
						Subject
					</p>
				</div>
			),
			key: 'subject',
			render: record => {
				return (
					<OpenModal
						isTextLink={true}
						title={
							record.date_opened !== '' ? (
								<div>{record.subject} </div>
							) : (
								<div style={{ fontWeight: 'bold' }}>{record.subject} </div>
							)
						}
						modalContent={
							<div
								dangerouslySetInnerHTML={{
									__html: record.content,
								}}
							></div>
						}
						hideFooter={true}
						handleOnClick={() => {
							messageOpened(record);
						}}
						icon={'message'}
					/>
				);
			},
		},
		{
			title: (
				<div className="gx-d-flex gx-align-items-center">
					<Date className="date-icon gx-mr-2 gx-mt-1" />
					<p
						className="gx-text-secondary"
						style={{
							textAlign: 'left',
						}}
					>
						Opened
					</p>
				</div>
			),
			dataIndex: 'date_opened',
			key: 'date_opened',
		},
	];

	useEffect(() => {
		props.requestToFetchEmailMessages();
	}, []);

	useEffect(() => {
		props.loginInfo.emailMessagesFetched ? setLoader(false) : setLoader(true);
	}, [props.loginInfo.emailMessagesFetched]);

	return (
		<div>
			{!loader && props.loginInfo.emailMessagesFetched && (
				<div className="gx-app-login-container" style={{}}>
					<Card className="card">
						<Message
							className="gx-text-primary gx-mr-2 gx-mt-1"
							style={{
								width: '2.5rem',
								marginBottom: '1rem',
								float: 'right',
							}}
						/>
						<br />
						<div className="gx-d-flex gx-align-items-center">
							<h1 style={{ marginTop: '0.6em' }}>Messages</h1>
							<p
								className="bg-primary-gradient"
								style={{
									color: 'white',
									paddingRight: '1em',
									paddingLeft: '1em',
									marginLeft: '1em',
									marginTop: '1.8em',
									borderRadius: '3px',
								}}
							>
								{` ${props.loginInfo.emailMessages.summary.total_unread_count} unread`}
							</p>
						</div>
						<br />
						<Table
							rowKey="id"
							columns={emailTablecolumns}
							dataSource={props.loginInfo.emailMessages.array_of_emails}
							style={{ background: 'white' }}
							pagination={{ size: 'small' }}
						/>
					</Card>
				</div>
			)}
			{loader && (
				<Widget styleName="gx-card">
					<CircularProgress className="gx-profile-content" />
				</Widget>
			)}
		</div>
	);
};

function matchDispatchToProps(dispatch) {
	return bindActionCreators(
		Object.assign({
			requestToFetchEmailMessages: requestToFetchEmailMessages,
			requestToMarkEmailRead: requestToMarkEmailRead,
		}),
		dispatch,
	);
}

function mapStateToProps(state) {
	return {
		loginInfo: state.loginInfo,
	};
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(MessagesList));
