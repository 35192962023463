import React from 'react';
import { List } from 'antd';
import { connect } from 'react-redux';

const ProgressMenu = props => {
	//this Step will probably have to be mapped

	const myMenuObj = props.ecommerce.menuObjects;

	const currNum = props.screenNum;
	//https://ant.design/components/list/#components-list-demo-simple

	return (
		<div style={{ 'word-wrap': 'break-word' }}>
			<h2 style={{ textAlign: 'Center' }}>Registration Steps</h2>
			<hr />
			<List
				// header={<div>Header</div>}
				// bordered
				dataSource={myMenuObj}
				renderItem={item => (
					<List.Item
						className={currNum === item.screen ? 'gx-bg-primary-light' : ''}
						style={{
							color: currNum === item.screen ? 'white' : '',
							border: 'none',
						}}
					>
						<p style={{ marginLeft: '1rem', marginTop: '0.6rem' }}>
							{item.screen}. {item.button}
						</p>
					</List.Item>
				)}
			/>
		</div>
	);
};

const mapStateToProps = state => {
	const myState = { ...state };

	return {
		auth: myState.auth,
		initialData: myState.loginInfo,
		ecommerce: myState.ecommerce,
	};
};

export default connect(mapStateToProps)(ProgressMenu);
