import auth from '../loginAuth/loginAuth';

const xcdapi = 'https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm';

// returns list of products that the user brought
const getUserProductList = async () => {
	let result = await fetch(
		`${xcdapi}?&module=products&component=user_products&function=get_user_products`,
		{
			method: 'GET',
			headers: {
				Authorization: JSON.parse(auth.getCookie('jwt')),
			},
		},
	);
	let resultJson = await result.json();

	return resultJson;
};

const getProductCategory = async () => {
	let result = await fetch(
		`${xcdapi}?&module=products&component=products&function=get_categories`,
		{
			method: 'GET',
			headers: {
				Authorization: JSON.parse(auth.getCookie('jwt')),
			},
		},
	);
	let resultJson = await result.json();

	return resultJson;
};

const getUserProductTags = async (source_hex) => {
	let result = await fetch(
		`${xcdapi}?source=${source_hex}&module=client&component=tags&function=see_tags`,
		{
			method: 'GET',
			headers: {
				Authorization: JSON.parse(auth.getCookie('jwt')),
			},
		},
	);
	let resultJson = await result.json();

	return resultJson;
};

const UserProductsAPIs = {
	getUserProductList,
	getProductCategory,
	getUserProductTags,
};

export default UserProductsAPIs;
