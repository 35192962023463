import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Upload, Card, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { requestUploadProfilePic } from '../../appRedux/actions/LoginPageInfo';
import imageCompression from 'browser-image-compression';

import { ReactComponent as ManageProfileUploadPicture } from '../../assets/icons/ManageProfileUploadPicture.svg';
import '../UpdateAccount/styles/custom.less';

const UpdatePicture = props => {
	const [loading, setLoading] = useState(false);
	const [imageUrl, setImageUrl] = useState('');

	const dispatch = useDispatch();

	const handleImageCompression = file => {
		return new Promise(function (resolve, reject) {
			imageCompression(file, {
				maxSizeMB: 1,
				maxWidthOrHeight: 1000,
				useWebWorker: true,
			})
				.then(function (compressedFile) {
					resolve(compressedFile);
				})
				.catch(function (error) {
					console.log(error.message);
				});
		});
	};

	const onPreview = options => {
		handleImageCompression(options.file).then(image => {
			let reader = new FileReader();
			reader.onloadend = async function () {
				image = reader.result;
				setImageUrl(image);

				let formData = new FormData();
				formData.append('CustomerSourceHexID', props.initialData.source_hex);

				formData.append('server_uuid', props.auth.contact_uuid);
				formData.append('ReviewID', props.initialData.reviewerID);
				formData.append('base64Img', image);
				dispatch(requestUploadProfilePic({ formData: formData }));
			};
			reader.readAsDataURL(image);
		});
	};

	const getBase64 = (img, callback) => {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	};

	const onUploadChange = info => {
		if (info.file.status === 'uploading') {
			setLoading(true);
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, imageUrl => setLoading(false));
		}
	};

	const beforeUpload = file => {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			message.error('You can only upload JPG/PNG file!');
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error('Image must smaller than 2MB!');
		}
		return isJpgOrPng && isLt2M;
	};

	useEffect(() => {
		setImageUrl(props.initialData.picture);
		setLoading(false);
	}, [props.initialData.picture]);

	return (
		<div>
			<Card className="card">
				<ManageProfileUploadPicture
					className="manage-profile-page-icons gx-mr-2 gx-mt-1 fg-primary-dark-light"
					style={{ float: 'right' }}
				/>

				<h2 className="gx-text-secondary" style={{ marginBottom: '1rem' }}>
					Update Picture
				</h2>
				<br />
				<br />
				<div
					style={{
						'display': 'flex',
						'justify-content': 'flex-start',
						'align-items': 'left',
						'margin-left': 'auto',
						'margin-right': 'auto',
					}}
				>
					<Upload
						accept="image/*"
						customRequest={onPreview}
						listType="picture"
						onChange={onUploadChange}
						showUploadList={false}
						beforeUpload={beforeUpload}
					>
						<div style={{ width: '100%' }}>
							{imageUrl && loading === false ? (
								<img src={imageUrl} />
							) : (
								<div>
									{loading ? <LoadingOutlined /> : <PlusOutlined />}
									<div style={{ marginTop: 8 }}>Upload</div>
								</div>
							)}
							<br />
							<br />

							<p>Click picture to update</p>
						</div>
					</Upload>
				</div>
			</Card>
		</div>
	);
};

const mapStateToProps = state => {
	const myState = { ...state };

	return {
		auth: myState.auth,
		initialData: myState.loginInfo,
	};
};

export default connect(mapStateToProps)(UpdatePicture);
