import React from 'react';
import { useDispatch, connect } from 'react-redux';
import { Avatar, Popover } from 'antd';
import { useHistory } from 'react-router-dom';

import auth from '../../loginAuth/loginAuth';

import { requestLogoutUser } from '../../appRedux/actions/Auth';
import { requestEraseProfileData } from '../../appRedux/actions/LoginPageInfo';

const UserInfo = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const onLogout = () => {
		auth.deleteCookie('jwt');
		auth.deleteCookie('ssotoken');
		auth.deleteCookie('contact_uuid');
		dispatch(requestLogoutUser(props));
		dispatch(requestEraseProfileData(props));

		history.push('/signin');
	};

	const userMenuOptions = (
		<ul className="gx-user-popover">
			<li onClick={() => onLogout()}>Logout</li>
		</ul>
	);

	return (
		<Popover
			overlayClassName="gx-popover-horizantal"
			placement="bottomRight"
			content={userMenuOptions}
			trigger="click"
		>
			<Avatar
				src={'https://via.placeholder.com/150'}
				className="gx-avatar gx-pointer"
				alt=""
			/>
		</Popover>
	);
};

const mapStateToProps = (state) => {
	const myState = { ...state };

	return {
		auth: myState.auth,
		initialData: myState.loginInfo,
	};
};

export default connect(mapStateToProps)(UserInfo);
