import { useDispatch, connect } from 'react-redux';
import auth from '../../../loginAuth/loginAuth';
import { Button, Form, Input, Card, notification, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { requestLoginFormData } from '../../../appRedux/actions/LoginPageInfo';

import { requestResetPassword } from '../../../appRedux/actions/Auth';

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import { ReactComponent as ManageProfileResetPassword } from '../../../assets/icons/ManageProfileResetPassword.svg';
import '../../UpdateAccount/styles/custom.less';

// TODO: Have check type error function that you can reuse
// get rid of all cookies and make another api call. slot into Redux again
// always route to main/profilehome
// delete all unused libraries

const ResetPassword = props => {
	const history = useHistory();
	const location = useLocation();
	const { Title } = Typography;

	const [routeLocation, setRouteLocation] = useState('');
	const [myPassword, setMyPassword] = useState('');
	const [myConfirmPassword, setMyConfirmPassword] = useState('');
	const [submitLoad, setSubmitLoad] = useState(false);

	const dispatch = useDispatch();

	const openNotificationWithIcon = () => {
		notification['error']({
			message: 'An error has occurred, please try again.',
		});
	};

	const passwordNotMatchNotification = () => {
		notification['error']({
			message: 'Your password does not match. Please Try again.',
		});
	};

	let login_form = props.initialData.login_form;

	useEffect(() => {
		if (!props.initialData.login_form) {
			if (props.initialData.login_form) return;
			const appdir = props.initialData.appdir || JSON.parse(auth.getCookie('appdir'));
			if (!appdir) return;
			dispatch(requestLoginFormData({ appdir }));
		}
	}, [props.initialData.login_form]);

	useEffect(() => {
		if (props.auth.resetPasswordSuccess === true) {
			history.push('/main/profilehome');
		} else if (props.auth.resetPasswordSuccess === false) {
			openNotificationWithIcon('error');
			setSubmitLoad(false);
		}
	}, [props.auth.resetPasswordSuccess]);

	useEffect(() => {
		let checkRoute = location.pathname.split('/');
		if (checkRoute[1] === 'resetpassword') {
			setRouteLocation('resetpassword');
		}
	}, []);

	const onPasswordChange = e => {
		setMyPassword(e.target.value);
	};

	const onConfirmPasswordChange = e => {
		setMyConfirmPassword(e.target.value);
	};

	const onSubmitPassword = () => {
		setSubmitLoad(true);

		let formData = new FormData();
		formData.append('password', myPassword);
		formData.append('react_login', 1);

		let headers = new Headers();
		let jwtToken = JSON.parse(auth.getCookie('jwt'));

		headers.append('Authorization', jwtToken);

		if (myPassword !== myConfirmPassword) {
			passwordNotMatchNotification('error');
			setSubmitLoad(false);
		} else {
			// make the POST request to API
			dispatch(
				requestResetPassword({
					appdir: props.initialData.appdir,
					formData: formData,
				}),
			);
		}
	};

	const routeLocationStr = 'resetpassword';

	return (
		<div
			className={routeLocation === routeLocationStr ? 'gx-app-login-container' : ''}
			style={{ textAlign: routeLocation === routeLocationStr ? 'center' : 'left' }}
		>
			{routeLocation === routeLocationStr ? (
				login_form && (
					<div style={{ textAlign: 'center' }}>
						<img src={login_form.logo} />
					</div>
				)
			) : (
				<div></div>
			)}

			<Card
				className={routeLocation === routeLocationStr ? 'gx-card' : ''}
				// title={routeLocation === routeLocationStr ? '' : 'Reset Password'}
				headStyle={
					routeLocation === routeLocationStr
						? {}
						: {
								backgroundColor: props.initialData.login_form.primary_color,
								color: 'white',

								border: 0,
								fontSize: '20px',
						  }
				}
			>
				{routeLocation === routeLocationStr ? (
					<h2
						style={{
							'font-family': 'NoirPro, sans-serif',
							'font-size': '14px',
							'color': '#535353',
							'font-weight': '600',
							'text-transform': 'uppercase',
						}}
					>
						Reset Password
					</h2>
				) : (
					<div style={{ marginBottom: '3rem' }}>
						<ManageProfileResetPassword
							className="manage-profile-page-icons gx-mr-2 gx-mt-1 fg-primary-dark-light"
							style={{ float: 'right' }}
						/>

						<h2 className="gx-text-secondary">Reset Password</h2>
					</div>
				)}

				<Form layout="vertical" className="gx-signin-form gx-form-row0" id="myForm">
					<Form.Item
						initialValue={''}
						label={'Password'}
						validateTrigger="onBlur"
						rules={[
							{
								required: true,
								message: 'Please enter your password',
							},
							{
								min: 5,
								message: 'Password must be minimum 5 characters',
							},
						]}
						name="password"
					>
						<Input.Password
							placeholder="Password"
							onChange={onPasswordChange}
							value={myPassword}
							iconRender={visible =>
								visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
							}
						/>
					</Form.Item>
					<Form.Item
						initialValue={''}
						label={'Confirm Password'}
						validateTrigger="onBlur"
						rules={[
							{
								required: true,
								message: 'Please enter your password',
							},
							{
								min: 5,
								message: 'Password must be minimum 5 characters',
							},
						]}
						name="confirm_password"
					>
						<Input.Password
							placeholder="Confirm Password"
							onChange={onConfirmPasswordChange}
							value={myConfirmPassword}
							iconRender={visible =>
								visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
							}
						/>
					</Form.Item>

					<Button
						type="default"
						htmlType="submit"
						key="submit"
						onClick={onSubmitPassword}
						form="myForm"
						shape="round"
						className="gx-ml-auto button-primary gx-font-weight-semi-bold shadow-2"
						style={{
							border: 'none',
							padding: '0 2.5rem',
						}}
						loading={submitLoad}
					>
						Submit
					</Button>
				</Form>
			</Card>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		auth: state.auth,
		initialData: state.loginInfo,
	};
};

export default connect(mapStateToProps)(ResetPassword);
