import React from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Popover } from 'antd';
import { userSignOut } from 'appRedux/actions/Auth';

const Data = () => {
	return (
		<div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
			<h1>Data</h1>
		</div>
	);
};

export default Data;
