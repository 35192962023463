import {
	REQUEST_USER_PRODUCT_LIST,
	RECEIVE_USER_PRODUCT_LIST,
	REQUEST_USER_PRODUCTS_LIST_SELECTION_VIEW,
	RECEIVE_USER_PRODUCTS_LIST_SELECTION_VIEW,
	REQUEST_PRODUCT_CATEGORY,
	RECEIVE_PRODUCT_CATEGORY,
	REQUEST_ERASE_USER_PRODUCTS_DATA,
	RECEIVE_ERASE_USER_PRODUCTS_DATA,
	REQUEST_TOGGLE_CALENDAR_VIEW,
	RECEIVE_TOGGLE_CALENDAR_VIEW,
	REQUEST_USER_PRODUCT_TAGS,
	RECEIVE_USER_PRODUCT_TAGS,
} from 'constants/ActionTypes';

export const requestUserProductList = (data) => {
	return {
		type: REQUEST_USER_PRODUCT_LIST,
		payload: data,
	};
};

export const receiveUserProductList = (data) => {
	return {
		type: RECEIVE_USER_PRODUCT_LIST,
		payload: data,
	};
};

export const requestUserProductListSelectionView = (data) => {
	return {
		type: REQUEST_USER_PRODUCTS_LIST_SELECTION_VIEW,
		payload: data,
	};
};

export const receiveUserProductListSelectionView = (data) => {
	return {
		type: RECEIVE_USER_PRODUCTS_LIST_SELECTION_VIEW,
		payload: data,
	};
};

export const requestProductCategory = (data) => {
	return {
		type: REQUEST_PRODUCT_CATEGORY,
		payload: data,
	};
};

export const receiveProductCategory = (data) => {
	return {
		type: RECEIVE_PRODUCT_CATEGORY,
		payload: data,
	};
};

export const requestEraseUserProductsData = (data) => {
	return {
		type: REQUEST_ERASE_USER_PRODUCTS_DATA,
		payload: data,
	};
};

export const receiveEraseUserProductsData = (data) => {
	return {
		type: RECEIVE_ERASE_USER_PRODUCTS_DATA,
		payload: data,
	};
};

export const requestToggleCalendarView = (data) => {
	return {
		type: REQUEST_TOGGLE_CALENDAR_VIEW,
		payload: data,
	};
};

export const receiveToggleCalendarView = (data) => {
	return {
		type: RECEIVE_TOGGLE_CALENDAR_VIEW,
		payload: data,
	};
};

export const requestUserProductTags = (data) => {
	return {
		type: REQUEST_USER_PRODUCT_TAGS,
		data,
	};
};

export const receiveUserProductTags = (data) => {
	return {
		type: RECEIVE_USER_PRODUCT_TAGS,
		payload: data,
	};
};
