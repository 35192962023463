import React from 'react';

interface Props {
	label: string;
	color: string;
	error?: string;
	required: string;
	name: string;
}

export default ({ label, color, error, required, name }: Props) =>
	!label || label === '' ? null : (
		<div id={`${name}_title`}>
			<div style={{ display: 'flex', alignItems: 'flex-start' }}>
				<h4 dangerouslySetInnerHTML={{ __html: label }} className="gx-mt-2" />
				{required && (
					<span
						className="gx-ml-2"
						style={{
							fontSize: '24px',
							color: '#A94442',
						}}
					>
						*
					</span>
				)}
				{error && (
					<span
						style={{
							marginLeft: 20,
							fontWeight: 400,
							fontSize: 16,
							background: '#F2DEDE',
							color: '#A94442',
						}}
						className="gx-px-2 gx-mt-2"
					>
						{error}
					</span>
				)}
			</div>
		</div>
	);
